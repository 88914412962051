.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 900px;
  height: 500px;
  background: rgb(255,255,255);
  background: linear-gradient(114deg, rgba(255,255,255,1) 86%, rgba(102,210,235,1) 100%);
  margin: 100px auto 0;
  margin-top: 250px;
  margin-bottom: 75px;
  word-break: break-all;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.titleh{
font-size: 1.5rem;
color: #66d2eb;
text-shadow: 1px 1px 1px #295c68;

}

.onglets {
  display: flex;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(126, 126, 126, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: rgb(255, 255, 255);
  border-bottom: 1px solid transparent;
  color: #66d2eb;
  text-decoration: underline;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #66d2eb;

}


.contenu-tabs {
  flex-grow : 1;
}
.contenu {
  background: rgba(255, 255, 255, 0);
  padding: 20px;
  width: 95%;
  height: 100%;
  display: none;
}

.active-contenu {
  display: block;
}
.ulform{
    columns: 100px 2;
}
.liform{
    font-size: 0.8rem;
}
.spc{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
}


@media (max-width:512px) and (orientation: portrait) {
    .container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 350px;
        height: 780px;
        background: rgb(255,255,255);
        background: linear-gradient(114deg, rgba(255,255,255,1) 86%, rgba(102,210,235,1) 100%);
        margin: 100px auto 0;
        margin-top: 150px;
        margin-bottom: 50px;
        word-break: break-all;
        border: none;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
      .titleh{
      font-size: 1.2rem;
      color: #66d2eb;
      text-shadow: 1px 1px 1px #295c68;
      
      }
      
      .onglets {
        display: flex;
      }
      .tabs {
        padding:0;
        font-size: 0.6rem;
        text-align: center;
        width: 50%;
        background: rgba(126, 126, 126, 0.075);
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
        box-sizing: content-box;
        position: relative;
        outline: none;
      }
      .tabs:not(:last-child){
        border-right: 1px solid rgba(0, 0, 0, 0.274);
      }
      
      .active-tabs  {
        background: rgb(255, 255, 255);
        border-bottom: 1px solid transparent;
        color: #66d2eb;
        text-decoration: underline;
      }
      
      .active-tabs::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 5px;
        background: #66d2eb;
      
      }
      
      
      .contenu-tabs {
        flex-grow : 1;
      }
      .contenu {
        background: rgba(255, 255, 255, 0);
        padding: 20px;
        width: 95%;
        height: 100%;
        display: none;
      }
      
      .active-contenu {
        display: block;
      }
      .ulform{
        columns: 1;
        padding: 0;
      }
      .ulforme{
        columns: 1;
        padding: 0;
      }
      .liform{
          font-size: 0.8rem;
          width: 330px;
      }
      .spc{
          font-size: 0.9rem;
          font-weight: bold;
          text-align: center;
          margin-top: 15px;
          width: 300px;
      }
}