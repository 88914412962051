.margin{
    margin-top: 120px;
    height: 500px;
    background-image:url('../Assets/sill.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.reference{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1em;
}
.btnref{
    height:25px;
    width: 145px;
    align-self: center;
    justify-self: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border: solid 2px #9ee468;
    border-radius: 4px;
    cursor: pointer;
}
.btnref:hover{
    background-color: #9ee468;
}

.Dref{
    margin-top: 100px;
}
.Bref{
    margin-left: 50px;
    color: #9ee468;
    text-shadow: 1px 1px 1px #71ad43;
}
.ref{
    height: 125px;
    align-self: center;
    justify-self: center;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Ans{
    height: 200px;
  }
.Data{
    height: 100px;
    margin-top: 15px;
  }

.BvnS{
    font-size:x-large;
    color: #dd448b;
    text-shadow: 1px 1px 1px #ffffff;
}
.Bvn{
    display: none;
}

.BCN{
    font-size:large;
    color: rgb(0, 0, 0);
    color: #66d2eb;
    text-shadow: 1px 1px 1px #3e8b9c;

}
.BAS{
    font-size:larger;
    color: #000000
   
}
.BA{
   display: none;
}

.text{
    width: 540px;
    text-align: center;
}

.text2{
    width: 750px;
    margin-top: 5px;
    text-align: center;
}

.intituler{
 display: flex;
 flex-direction: column;
}
 
.imgC{
height: 300px;
cursor: pointer;
margin-right: 10px;
}
.grid{
    display: grid;
    margin-top: 100px;
    margin-bottom: 100px;
    grid-template-columns: 5vw 45vw 45vw 5vw ;
    grid-template-rows: 5vh 45vh 45vh 5vh ;
    gap:0px;
    grid-template-areas:
      ". . . ."
      ". A C ."
      ". B D ."
      ". . . ."
      ;
}

.coaching{
    display: flex;
    flex-direction: row;
    grid-area: A;
}
.conseil{
    display: flex;
    flex-direction: row;
    grid-area: B;
}
.formation{
    display: flex;
    flex-direction: row;
    grid-area: C;
    
}
.team{
    display: flex;
    flex-direction: row;
    grid-area: D;
}

.nom{
    color: #EE814C;
    max-width: 400px;
}

.nom2{
    color: #dd448b;
    max-width: 400px;
}
.nom3{
    color: #66d2eb;
    max-width: 400px;
}
.nom4{
    color: #9ee468;
    max-width: 400px;
}
.sp{
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 400px;
    text-decoration: underline;
}

.sp2{

    margin-bottom: 10px;
    font-size: smaller;
    font-weight: bold;
    max-width: 400px;
}

.listC{
    padding: 0;
    list-style: none;
    font-size: 0.9rem;
    max-width: 400px;
}

.area{
    font-size: 0.8rem;
    text-align: left;
    max-width: 400px;
}

@media (max-width:512px) and (orientation: portrait) {
    .reference{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 1em;
    }

    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Bref{
        font-size:1.9rem;
        text-align: center;
        margin: 0;
    }
    .Bvn{
        font-size:x-large;
        color: #dd448b;
        text-shadow: 1px 1px 1px #ffffff;
        font-size:1.2rem;
        text-align: center;
        display: block;
    }
    .BvnS{
        display: none;
    }
    
    .BCN{
        font-size:1rem;
        text-align: center;
        width: 280px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .BA{
        font-size:larger;
        color: #000000;
        display: block;
        
    }
    .BAS{
        display: none;
       
    }
    
    .text{
        width: 300px;
        text-align: center;
    }
    
    .text2{
        width: 300px;
        margin-top: 5px;
        text-align: center;
    }
    
    .intituler{
     display: flex;
     flex-direction: column;
     text-align: center;

    }
    .Ans{
      height: 150px;
    }
     
    .imgC{
    height: 250px;
    width: 250px;
    }
    .grid{
        display: grid;
        margin-top: 50px;
        grid-template-columns: 5vw 90vw 5vw ;
        grid-template-rows: 40vh 40vh 40vh 40vh ;
        gap:0px;
        grid-template-areas:
          " . A . "
          " . C . "
          " . B . "
          " . D . "
          ;
    }
    
    .coaching{
        display: flex;
        flex-direction: row;
        grid-area: A;
    }
    .conseil{
        display: flex;
        flex-direction: row;
        grid-area: B;
    }
    .formation{
        display: flex;
        flex-direction: row;
        grid-area: C;
    }
    .team{
        display: flex;
        flex-direction: row;
        grid-area: D;
    }
    
    .nom{
        color: #EE814C;

    }
    
    .nom2{
        color: #dd448b;

    }
    .nom3{
        color: #66d2eb;
    }
    .nom4{
        color: #9ee468;
       
    }
    .sp{
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: small;
        
    }
    
    .sp2{
      display: none;
    }
    
    .listC{
        padding: 0;
        list-style: none;
        font-size: 0.8rem;
        overflow: scroll;
        max-height: 130px;
        
    }
    
    .area{
        font-size: 0.8rem;
        max-width: 400px;
        overflow: scroll;
        max-height: 110px;
        text-align: center
    }

    .margin{
        margin-top: 80px;
        height: 250px;
        background-image:url('../Assets/sill.jpg');
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

 }