.icon{
    height: 125px;
    align-self: center;
    justify-self: center;
}
.gridR{
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-template-rows: 125px;
    grid-gap: 1em;
}
.content{
    margin-top: 200px;
    margin-left: 25px ;
    margin-right: 25px ;
    margin-bottom: 50px;
}
.titreR{
    text-align: center;
    color: #9ee468;
    text-shadow: 1px 1px 1px #71ad43;
    text-decoration: underline;
}
@media (max-width:512px) and (orientation: portrait) {
    .icon{
        height: 125px;
        align-self: center;
        justify-self: center;
    }
    .gridR{
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
        grid-template-rows: 125px;
        grid-gap: 1em;
    }
    .content{
        margin-top: 150px;
        margin-left: 25px ;
        margin-right: 25px ;
        margin-bottom: 50px;
    }
    .titreR{
        text-align: center;
        color: #9ee468;
        text-shadow: 1px 1px 1px #71ad43;
        text-decoration: underline;
    }
}