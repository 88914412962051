@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700);
* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  
}

a{
  text-decoration: none;
}






.burger{
    height: 40px;
    width: 40px;
    background: transparent;
    border: none;
    color: black;
    display: none;
}


.navbar{
    box-shadow: 0 4px 8px rgb(118 118 120 / 20%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgb(255, 255, 255);
    z-index: 4;
}
.logo{
    margin-right: 50vmin;
    height: 150px;
    cursor: pointer;
}
.F:hover{
    color: rgb(0, 0, 0);
}
.E:hover{
    color: rgb(0, 0, 0);
}
.C:hover{
    color: rgb(0, 0, 0);
}
.R:hover{
    color: rgb(0, 0, 0);
}
.borderXwidth{
    display: flex;
    flex-direction: row;
}

.borderXwidth a{
    text-decoration: none;
    margin-left: 20px;
    padding: 10px 10px;
    color: rgb(107, 107, 107);
    position: relative;
    z-index: 0;
    cursor: pointer;
}


div.borderXwidth .F:before, div.borderXwidth .F:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #66d2eb;
    transition: all 0.3s;
}

div.borderXwidth .F:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .F:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .F:hover:before, div.borderXwidth .F:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .E:before, div.borderXwidth .E:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: rgb(239,228,39);
    transition: all 0.3s;
}

div.borderXwidth .E:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .E:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .E:hover:before, div.borderXwidth .E:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .C:before, div.borderXwidth .C:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #dd448b;
    transition: all 0.3s;
}

div.borderXwidth .C:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .C:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .C:hover:before, div.borderXwidth .C:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .R:before, div.borderXwidth .R:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #9ee468 ;
    transition: all 0.3s;
}

div.borderXwidth .R:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .R:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .R:hover:before, div.borderXwidth .R:hover:after
{
    opacity: 1;
    width: 100%;
}

@media (max-width:512px) and (orientation: portrait){
.logo{
    margin-right: 30px;
    height: 120px;
}

.borderXwidth{
 flex-direction: column;
 justify-content: center;
 align-items: center;
 position: fixed;
 right: -100vw;
 bottom: 0;
 padding-right: 12px;
 height: 100vh;
 background-color: white;
 visibility: hidden;
}  

.show-nav .borderXwidth{
right: 0;
width: 100vw;
visibility: visible;
   }  

.F{
 display: block;
 padding: 1.5rem;
 font-size: 5vw;
}

.E{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}
.C{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}

.R{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}
.burger{
display: block;
position: fixed;
top: 1rem;
right: 1rem;
margin-top: 25px;
margin-right: 25px;
}
.burger:hover{
    cursor: pointer;
}

.burger-nav,
.burger-nav::before,
.burger-nav::after{
display: block;
height: 3px;
width: 40px;
position: relative;
border-radius: 3px;
background-color: black;
transition: all 0.5s ease-in-out;
}

.burger-nav::before,
.burger-nav::after{
    content: "";
    position: absolute;
    left: 0;

}


.burger-nav::after{
    -webkit-transform: translateY(12px);
            transform: translateY(12px);

}

.burger-nav::before{
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
}
.show-nav .burger-nav::before{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.show-nav .burger-nav::after{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    
}
.show-nav .burger-nav{
    width: 0;
    background: transparent;
}
}
.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #272727;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.253), 0px -3px 3px rgba(0, 0, 0, 0.219);
    height: 220px;
}

.mention{
    margin-top: 10px;
    color: rgb(255, 255, 255);
}

.mail{
    color: rgb(255, 255, 255);
}

.mailto{
    color: rgba(255, 255, 255, 0.432);
    text-decoration: none;
}

.mailto:hover{
    color: rgb(255, 255, 255);
}

.number{
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    margin-top: 10px;
}

.btn{
    height: 50px;
    cursor: pointer;
}
.contact{
    color: rgb(255, 255, 255);
}

@media (max-width: 512px) and (orientation: portrait){
    .footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.253), 0px -3px 3px rgba(0, 0, 0, 0.219);
        height: 220px;
    }
    
    .mention{
        margin-top: 10px;
        font-size: 0.7rem;
    }
    
    .mail{
        color: rgb(255, 255, 255);
    }
    
    .mailto{
        color: rgba(255, 255, 255, 0.432);
        text-decoration: none;
    }
    
    .mailto:hover{
        color: rgb(255, 255, 255);
    }
    
    .number{
        color: rgb(255, 255, 255);
        margin-bottom: 10px;
        margin-top: 10px;
    }
    
    .btn{
        height: 50px;
        cursor: pointer;
    }
}
.margin{
    margin-top: 120px;
    height: 500px;
    background-image:url(/static/media/sill.5ab3b131.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.reference{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1em;
}
.btnref{
    height:25px;
    width: 145px;
    align-self: center;
    justify-self: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    border: solid 2px #9ee468;
    border-radius: 4px;
    cursor: pointer;
}
.btnref:hover{
    background-color: #9ee468;
}

.Dref{
    margin-top: 100px;
}
.Bref{
    margin-left: 50px;
    color: #9ee468;
    text-shadow: 1px 1px 1px #71ad43;
}
.ref{
    height: 125px;
    align-self: center;
    justify-self: center;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Ans{
    height: 200px;
  }
.Data{
    height: 100px;
    margin-top: 15px;
  }

.BvnS{
    font-size:x-large;
    color: #dd448b;
    text-shadow: 1px 1px 1px #ffffff;
}
.Bvn{
    display: none;
}

.BCN{
    font-size:large;
    color: rgb(0, 0, 0);
    color: #66d2eb;
    text-shadow: 1px 1px 1px #3e8b9c;

}
.BAS{
    font-size:larger;
    color: #000000
   
}
.BA{
   display: none;
}

.text{
    width: 540px;
    text-align: center;
}

.text2{
    width: 750px;
    margin-top: 5px;
    text-align: center;
}

.intituler{
 display: flex;
 flex-direction: column;
}
 
.imgC{
height: 300px;
cursor: pointer;
margin-right: 10px;
}
.grid{
    display: grid;
    margin-top: 100px;
    margin-bottom: 100px;
    grid-template-columns: 5vw 45vw 45vw 5vw ;
    grid-template-rows: 5vh 45vh 45vh 5vh ;
    grid-gap:0px;
    gap:0px;
    grid-template-areas:
      ". . . ."
      ". A C ."
      ". B D ."
      ". . . ."
      ;
}

.coaching{
    display: flex;
    flex-direction: row;
    grid-area: A;
}
.conseil{
    display: flex;
    flex-direction: row;
    grid-area: B;
}
.formation{
    display: flex;
    flex-direction: row;
    grid-area: C;
    
}
.team{
    display: flex;
    flex-direction: row;
    grid-area: D;
}

.nom{
    color: #EE814C;
    max-width: 400px;
}

.nom2{
    color: #dd448b;
    max-width: 400px;
}
.nom3{
    color: #66d2eb;
    max-width: 400px;
}
.nom4{
    color: #9ee468;
    max-width: 400px;
}
.sp{
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 400px;
    text-decoration: underline;
}

.sp2{

    margin-bottom: 10px;
    font-size: smaller;
    font-weight: bold;
    max-width: 400px;
}

.listC{
    padding: 0;
    list-style: none;
    font-size: 0.9rem;
    max-width: 400px;
}

.area{
    font-size: 0.8rem;
    text-align: left;
    max-width: 400px;
}

@media (max-width:512px) and (orientation: portrait) {
    .reference{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        grid-gap: 1em;
    }

    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Bref{
        font-size:1.9rem;
        text-align: center;
        margin: 0;
    }
    .Bvn{
        font-size:x-large;
        color: #dd448b;
        text-shadow: 1px 1px 1px #ffffff;
        font-size:1.2rem;
        text-align: center;
        display: block;
    }
    .BvnS{
        display: none;
    }
    
    .BCN{
        font-size:1rem;
        text-align: center;
        width: 280px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .BA{
        font-size:larger;
        color: #000000;
        display: block;
        
    }
    .BAS{
        display: none;
       
    }
    
    .text{
        width: 300px;
        text-align: center;
    }
    
    .text2{
        width: 300px;
        margin-top: 5px;
        text-align: center;
    }
    
    .intituler{
     display: flex;
     flex-direction: column;
     text-align: center;

    }
    .Ans{
      height: 150px;
    }
     
    .imgC{
    height: 250px;
    width: 250px;
    }
    .grid{
        display: grid;
        margin-top: 50px;
        grid-template-columns: 5vw 90vw 5vw ;
        grid-template-rows: 40vh 40vh 40vh 40vh ;
        grid-gap:0px;
        gap:0px;
        grid-template-areas:
          " . A . "
          " . C . "
          " . B . "
          " . D . "
          ;
    }
    
    .coaching{
        display: flex;
        flex-direction: row;
        grid-area: A;
    }
    .conseil{
        display: flex;
        flex-direction: row;
        grid-area: B;
    }
    .formation{
        display: flex;
        flex-direction: row;
        grid-area: C;
    }
    .team{
        display: flex;
        flex-direction: row;
        grid-area: D;
    }
    
    .nom{
        color: #EE814C;

    }
    
    .nom2{
        color: #dd448b;

    }
    .nom3{
        color: #66d2eb;
    }
    .nom4{
        color: #9ee468;
       
    }
    .sp{
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: small;
        
    }
    
    .sp2{
      display: none;
    }
    
    .listC{
        padding: 0;
        list-style: none;
        font-size: 0.8rem;
        overflow: scroll;
        max-height: 130px;
        
    }
    
    .area{
        font-size: 0.8rem;
        max-width: 400px;
        overflow: scroll;
        max-height: 110px;
        text-align: center
    }

    .margin{
        margin-top: 80px;
        height: 250px;
        background-image:url(/static/media/sill.5ab3b131.jpg);
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

 }
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 900px;
  height: 500px;
  background: rgb(255,255,255);
  background: linear-gradient(114deg, rgba(255,255,255,1) 86%, rgba(102,210,235,1) 100%);
  margin: 100px auto 0;
  margin-top: 250px;
  margin-bottom: 75px;
  word-break: break-all;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.titleh{
font-size: 1.5rem;
color: #66d2eb;
text-shadow: 1px 1px 1px #295c68;

}

.onglets {
  display: flex;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(126, 126, 126, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: rgb(255, 255, 255);
  border-bottom: 1px solid transparent;
  color: #66d2eb;
  text-decoration: underline;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #66d2eb;

}


.contenu-tabs {
  flex-grow : 1;
}
.contenu {
  background: rgba(255, 255, 255, 0);
  padding: 20px;
  width: 95%;
  height: 100%;
  display: none;
}

.active-contenu {
  display: block;
}
.ulform{
    -webkit-columns: 100px 2;
            columns: 100px 2;
}
.liform{
    font-size: 0.8rem;
}
.spc{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
}


@media (max-width:512px) and (orientation: portrait) {
    .container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 350px;
        height: 780px;
        background: rgb(255,255,255);
        background: linear-gradient(114deg, rgba(255,255,255,1) 86%, rgba(102,210,235,1) 100%);
        margin: 100px auto 0;
        margin-top: 150px;
        margin-bottom: 50px;
        word-break: break-all;
        border: none;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
      .titleh{
      font-size: 1.2rem;
      color: #66d2eb;
      text-shadow: 1px 1px 1px #295c68;
      
      }
      
      .onglets {
        display: flex;
      }
      .tabs {
        padding:0;
        font-size: 0.6rem;
        text-align: center;
        width: 50%;
        background: rgba(126, 126, 126, 0.075);
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
        box-sizing: content-box;
        position: relative;
        outline: none;
      }
      .tabs:not(:last-child){
        border-right: 1px solid rgba(0, 0, 0, 0.274);
      }
      
      .active-tabs  {
        background: rgb(255, 255, 255);
        border-bottom: 1px solid transparent;
        color: #66d2eb;
        text-decoration: underline;
      }
      
      .active-tabs::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 5px;
        background: #66d2eb;
      
      }
      
      
      .contenu-tabs {
        flex-grow : 1;
      }
      .contenu {
        background: rgba(255, 255, 255, 0);
        padding: 20px;
        width: 95%;
        height: 100%;
        display: none;
      }
      
      .active-contenu {
        display: block;
      }
      .ulform{
        -webkit-columns: 1;
                columns: 1;
        padding: 0;
      }
      .ulforme{
        -webkit-columns: 1;
                columns: 1;
        padding: 0;
      }
      .liform{
          font-size: 0.8rem;
          width: 330px;
      }
      .spc{
          font-size: 0.9rem;
          font-weight: bold;
          text-align: center;
          margin-top: 15px;
          width: 300px;
      }
}
.lequipeC{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
}
.equipe{
    display: grid;
    grid-template-columns: 25vw 25vw 25vw 25vw ;
    grid-template-rows: 15vh 10vh 25vh 25vh;
    grid-gap:0px;
    gap:0px;
    grid-template-areas:
      " . h . . "
      " . f f g "
      " . d d g "
      " . . . . "
      ;
}

.textS{
    grid-area: d;
}

.direct{
    grid-area: f;
    justify-self: start;
    align-self: flex-end;
    margin-bottom: 10px;
    font-weight: bold;
}

.didier{
    grid-area: g;
    height: 200px;
    align-self: center;
    margin-left: 15px;
}
.titlesS{
    grid-area: h;
    align-self: flex-end;
    color: rgb(239,228,39);
    text-shadow: 1px 1px 1px rgb(99, 95, 20);
    text-decoration:underline ;
}
.titles{
    color: rgb(239,228,39);
    text-shadow: 1px 1px 1px rgb(99, 95, 20);
    text-decoration:underline ;  
}

@media (max-width:512px) and (orientation: portrait){
    .lequipeC{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 150px;
    }
    .equipe{
        display: grid;
        margin-bottom: 250px;
        grid-template-columns: 30vw 30vw 30vw ;
        grid-template-rows: 15vh 15vh 35vh 25vh;
        grid-gap:0px;
        gap:0px;
        grid-template-areas:
          " h h h "
          " f f f "
          " . g ."
          " d d d "
          ;
    }
    
    .textS{
        grid-area: d;
        width: 300px;
        text-align: center;
        justify-self: center;
        align-self: center;
        margin-top: 250px ;
    }
    
    .direct{
        grid-area: f;
        justify-self: start;
        align-self: flex-end;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    .didier{
        grid-area: g;
        height: 150px;
        margin-left:0;
    }
    .titlesS{
        grid-area: h;
        align-self: flex-end;
        justify-self: center;
        color: rgb(239,228,39);
        text-shadow: 1px 1px 1px rgb(99, 95, 20);
        text-decoration:underline ;
        font-size:x-large;
        margin-bottom: 5px;
    }
    .titles{
        color: rgb(239,228,39);
        text-shadow: 1px 1px 1px rgb(99, 95, 20);
        text-decoration:underline ;  
        font-size:x-large;
    }
}
.Cafe{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 200px;
    margin-bottom: 50px;
    background:rgb(255, 255, 255);
    border-radius:6px;
    width:800px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.centre{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.coffee{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CafeImg{
    height: 350px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 2px;
}

.textq{
    text-align: center;
    margin-bottom: 10px;
}
.textsq{
    font-weight: bold;
}

.titleq{
    margin-bottom: 10px;
    color: #dd448b;
    text-shadow: 1px 1px 1px #b63471;
    text-decoration:underline ;
}

@media (max-width:512px) and (orientation: portrait) {
    .Cafe{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 150px;
        margin-bottom: 50px;
        background:rgb(255, 255, 255);
        border-radius:6px;
        width: 320px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .centre{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .coffee{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .CafeImg{
        height: 250px;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: none;
        border-radius: 2px;
    }
    .textq{
        text-align: center;
        margin-bottom: 10px;
    }
    .textsq{
        font-weight: bold;
    }
    
    .titleq{
        margin-bottom: 10px;
        color: #dd448b;
        text-shadow: 1px 1px 1px #b63471;
        text-decoration:underline ;
        font-size: x-large;
    }
}

.icon{
    height: 125px;
    align-self: center;
    justify-self: center;
}
.gridR{
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-template-rows: 125px;
    grid-gap: 1em;
}
.content{
    margin-top: 200px;
    margin-left: 25px ;
    margin-right: 25px ;
    margin-bottom: 50px;
}
.titreR{
    text-align: center;
    color: #9ee468;
    text-shadow: 1px 1px 1px #71ad43;
    text-decoration: underline;
}
@media (max-width:512px) and (orientation: portrait) {
    .icon{
        height: 125px;
        align-self: center;
        justify-self: center;
    }
    .gridR{
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
        grid-template-rows: 125px;
        grid-gap: 1em;
    }
    .content{
        margin-top: 150px;
        margin-left: 25px ;
        margin-right: 25px ;
        margin-bottom: 50px;
    }
    .titreR{
        text-align: center;
        color: #9ee468;
        text-shadow: 1px 1px 1px #71ad43;
        text-decoration: underline;
    }
}
.contactR{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 226px;
    margin-bottom: 80px;
}
.Formu{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:#ffffff3d;
    border-radius:6px;
    padding:20px;
    padding-top:30px;
    width:500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.Formu input {
width: 450px;
margin-bottom: 15px;
background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;

}
.Formu textarea {
    width: 450px;
    margin-bottom: 15px;
    background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;
}
.Formu a {
    position:relative;
    padding:10px;
    border-radius:6px;
    border:0;
    background:#dd448b;
    font-size:1.2em;
    color:#fff;
    text-shadow:1px 1px 0px rgba(0,0,0,.1);
    box-shadow:0px 3px 0px #b63471;
}
.tC{
    margin-bottom: 25px;
    color: #dd448b;
    text-shadow: 1px 1px 1px #b63471;
    text-decoration:underline ;
}

@media (max-width:512px) and (orientation: portrait){


.contactR{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 150px;
    margin-bottom: 80px;
}
.Formu{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:#ffffff3d;
    border-radius:6px;
    padding:20px;
    padding-top:30px;
    width:280px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.Formu input {
width: 250px;
margin-bottom: 15px;
background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;

}
.Formu textarea {
    width: 250px;
    margin-bottom: 15px;
    background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;
}
.Formu a {
    position:relative;
    padding:10px;
    border-radius:6px;
    border:0;
    background:#dd448b;
    font-size:1.2em;
    color:#fff;
    text-shadow:1px 1px 0px rgba(0,0,0,.1);
    box-shadow:0px 3px 0px #b63471;
}
}
