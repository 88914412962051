.contactR{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 226px;
    margin-bottom: 80px;
}
.Formu{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:#ffffff3d;
    border-radius:6px;
    padding:20px;
    padding-top:30px;
    width:500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.Formu input {
width: 450px;
margin-bottom: 15px;
background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;

}
.Formu textarea {
    width: 450px;
    margin-bottom: 15px;
    background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;
}
.Formu a {
    position:relative;
    padding:10px;
    border-radius:6px;
    border:0;
    background:#dd448b;
    font-size:1.2em;
    color:#fff;
    text-shadow:1px 1px 0px rgba(0,0,0,.1);
    box-shadow:0px 3px 0px #b63471;
}
.tC{
    margin-bottom: 25px;
    color: #dd448b;
    text-shadow: 1px 1px 1px #b63471;
    text-decoration:underline ;
}

@media (max-width:512px) and (orientation: portrait){


.contactR{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 150px;
    margin-bottom: 80px;
}
.Formu{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:#ffffff3d;
    border-radius:6px;
    padding:20px;
    padding-top:30px;
    width:280px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.Formu input {
width: 250px;
margin-bottom: 15px;
background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;

}
.Formu textarea {
    width: 250px;
    margin-bottom: 15px;
    background:#f5f5f5;
  border:0;
  padding:10px;
  border-radius:6px;
  margin-bottom:10px;
  border:1px solid #eee;
}
.Formu a {
    position:relative;
    padding:10px;
    border-radius:6px;
    border:0;
    background:#dd448b;
    font-size:1.2em;
    color:#fff;
    text-shadow:1px 1px 0px rgba(0,0,0,.1);
    box-shadow:0px 3px 0px #b63471;
}
}