.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #272727;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.253), 0px -3px 3px rgba(0, 0, 0, 0.219);
    height: 220px;
}

.mention{
    margin-top: 10px;
    color: rgb(255, 255, 255);
}

.mail{
    color: rgb(255, 255, 255);
}

.mailto{
    color: rgba(255, 255, 255, 0.432);
    text-decoration: none;
}

.mailto:hover{
    color: rgb(255, 255, 255);
}

.number{
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    margin-top: 10px;
}

.btn{
    height: 50px;
    cursor: pointer;
}
.contact{
    color: rgb(255, 255, 255);
}

@media (max-width: 512px) and (orientation: portrait){
    .footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.253), 0px -3px 3px rgba(0, 0, 0, 0.219);
        height: 220px;
    }
    
    .mention{
        margin-top: 10px;
        font-size: 0.7rem;
    }
    
    .mail{
        color: rgb(255, 255, 255);
    }
    
    .mailto{
        color: rgba(255, 255, 255, 0.432);
        text-decoration: none;
    }
    
    .mailto:hover{
        color: rgb(255, 255, 255);
    }
    
    .number{
        color: rgb(255, 255, 255);
        margin-bottom: 10px;
        margin-top: 10px;
    }
    
    .btn{
        height: 50px;
        cursor: pointer;
    }
}