@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700');

* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  
}

a{
  text-decoration: none;
}





