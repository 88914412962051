.lequipeC{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 200px;
}
.equipe{
    display: grid;
    grid-template-columns: 25vw 25vw 25vw 25vw ;
    grid-template-rows: 15vh 10vh 25vh 25vh;
    gap:0px;
    grid-template-areas:
      " . h . . "
      " . f f g "
      " . d d g "
      " . . . . "
      ;
}

.textS{
    grid-area: d;
}

.direct{
    grid-area: f;
    justify-self: start;
    align-self: flex-end;
    margin-bottom: 10px;
    font-weight: bold;
}

.didier{
    grid-area: g;
    height: 200px;
    align-self: center;
    margin-left: 15px;
}
.titlesS{
    grid-area: h;
    align-self: flex-end;
    color: rgb(239,228,39);
    text-shadow: 1px 1px 1px rgb(99, 95, 20);
    text-decoration:underline ;
}
.titles{
    color: rgb(239,228,39);
    text-shadow: 1px 1px 1px rgb(99, 95, 20);
    text-decoration:underline ;  
}

@media (max-width:512px) and (orientation: portrait){
    .lequipeC{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 150px;
    }
    .equipe{
        display: grid;
        margin-bottom: 250px;
        grid-template-columns: 30vw 30vw 30vw ;
        grid-template-rows: 15vh 15vh 35vh 25vh;
        gap:0px;
        grid-template-areas:
          " h h h "
          " f f f "
          " . g ."
          " d d d "
          ;
    }
    
    .textS{
        grid-area: d;
        width: 300px;
        text-align: center;
        justify-self: center;
        align-self: center;
        margin-top: 250px ;
    }
    
    .direct{
        grid-area: f;
        justify-self: start;
        align-self: flex-end;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    .didier{
        grid-area: g;
        height: 150px;
        margin-left:0;
    }
    .titlesS{
        grid-area: h;
        align-self: flex-end;
        justify-self: center;
        color: rgb(239,228,39);
        text-shadow: 1px 1px 1px rgb(99, 95, 20);
        text-decoration:underline ;
        font-size:x-large;
        margin-bottom: 5px;
    }
    .titles{
        color: rgb(239,228,39);
        text-shadow: 1px 1px 1px rgb(99, 95, 20);
        text-decoration:underline ;  
        font-size:x-large;
    }
}