.Cafe{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 200px;
    margin-bottom: 50px;
    background:rgb(255, 255, 255);
    border-radius:6px;
    width:800px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.centre{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.coffee{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.CafeImg{
    height: 350px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 2px;
}

.textq{
    text-align: center;
    margin-bottom: 10px;
}
.textsq{
    font-weight: bold;
}

.titleq{
    margin-bottom: 10px;
    color: #dd448b;
    text-shadow: 1px 1px 1px #b63471;
    text-decoration:underline ;
}

@media (max-width:512px) and (orientation: portrait) {
    .Cafe{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 150px;
        margin-bottom: 50px;
        background:rgb(255, 255, 255);
        border-radius:6px;
        width: 320px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .centre{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .coffee{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .CafeImg{
        height: 250px;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: none;
        border-radius: 2px;
    }
    .textq{
        text-align: center;
        margin-bottom: 10px;
    }
    .textsq{
        font-weight: bold;
    }
    
    .titleq{
        margin-bottom: 10px;
        color: #dd448b;
        text-shadow: 1px 1px 1px #b63471;
        text-decoration:underline ;
        font-size: x-large;
    }
}
