.burger{
    height: 40px;
    width: 40px;
    background: transparent;
    border: none;
    color: black;
    display: none;
}


.navbar{
    box-shadow: 0 4px 8px rgb(118 118 120 / 20%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgb(255, 255, 255);
    z-index: 4;
}
.logo{
    margin-right: 50vmin;
    height: 150px;
    cursor: pointer;
}
.F:hover{
    color: rgb(0, 0, 0);
}
.E:hover{
    color: rgb(0, 0, 0);
}
.C:hover{
    color: rgb(0, 0, 0);
}
.R:hover{
    color: rgb(0, 0, 0);
}
.borderXwidth{
    display: flex;
    flex-direction: row;
}

.borderXwidth a{
    text-decoration: none;
    margin-left: 20px;
    padding: 10px 10px;
    color: rgb(107, 107, 107);
    position: relative;
    z-index: 0;
    cursor: pointer;
}


div.borderXwidth .F:before, div.borderXwidth .F:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #66d2eb;
    transition: all 0.3s;
}

div.borderXwidth .F:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .F:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .F:hover:before, div.borderXwidth .F:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .E:before, div.borderXwidth .E:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: rgb(239,228,39);
    transition: all 0.3s;
}

div.borderXwidth .E:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .E:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .E:hover:before, div.borderXwidth .E:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .C:before, div.borderXwidth .C:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #dd448b;
    transition: all 0.3s;
}

div.borderXwidth .C:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .C:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .C:hover:before, div.borderXwidth .C:hover:after
{
    opacity: 1;
    width: 100%;
}

div.borderXwidth .R:before, div.borderXwidth .R:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height: 2px;
    content: '';
    background: #9ee468 ;
    transition: all 0.3s;
}

div.borderXwidth .R:before
{
    left: 0px;
    top: 0px;
}

div.borderXwidth .R:after
{
    right: 0px;
    bottom: 0px;
}

div.borderXwidth .R:hover:before, div.borderXwidth .R:hover:after
{
    opacity: 1;
    width: 100%;
}

@media (max-width:512px) and (orientation: portrait){
.logo{
    margin-right: 30px;
    height: 120px;
}

.borderXwidth{
 flex-direction: column;
 justify-content: center;
 align-items: center;
 position: fixed;
 right: -100vw;
 bottom: 0;
 padding-right: 12px;
 height: 100vh;
 background-color: white;
 visibility: hidden;
}  

.show-nav .borderXwidth{
right: 0;
width: 100vw;
visibility: visible;
   }  

.F{
 display: block;
 padding: 1.5rem;
 font-size: 5vw;
}

.E{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}
.C{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}

.R{
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
}
.burger{
display: block;
position: fixed;
top: 1rem;
right: 1rem;
margin-top: 25px;
margin-right: 25px;
}
.burger:hover{
    cursor: pointer;
}

.burger-nav,
.burger-nav::before,
.burger-nav::after{
display: block;
height: 3px;
width: 40px;
position: relative;
border-radius: 3px;
background-color: black;
transition: all 0.5s ease-in-out;
}

.burger-nav::before,
.burger-nav::after{
    content: "";
    position: absolute;
    left: 0;

}


.burger-nav::after{
    transform: translateY(12px);

}

.burger-nav::before{
    transform: translateY(-12px);
}
.show-nav .burger-nav::before{
    transform: rotate(45deg);
}
.show-nav .burger-nav::after{
    transform: rotate(-45deg);
    
}
.show-nav .burger-nav{
    width: 0;
    background: transparent;
}
}